import React from "react";

const serviceContent = [
  { icon: "lnil-3d", name: "AI Chatbot Development", url: "/services/ai-ml-development" },
  { icon: "lnil-3d", name: "Generative AI development", url: "/services/ai-ml-development" },
  { icon: "lnil-3d", name: "Prompt Engineering", url: "/services/ai-ml-development" },
  { icon: "lnil-3d", name: "Computer Vision", url: "/services/ai-ml-development" },
  { icon: "lnil-3d", name: "Digital Transformation", url: "/services/it-consulting" },
  { icon: "lnil-3d", name: "Product Ideation & Development", url: "/services/it-consulting" },
  { icon: "lnil-3d", name: "Technology Consulting", url: "/services/it-consulting" },
  { icon: "lnil-3d", name: "Cloud Consulting", url: "/services/it-consulting" },
  { icon: "lnil-3d", name: "IT Audit Services", url: "/services/it-consulting" },
  { icon: "lnil-3d", name: "Website Design & Development", url: "/services/web-development" },
  { icon: "lnil-3d", name: "App Development", url: "/services/web-development" },
  { icon: "lnil-3d", name: "Digital Marketing", url: "/services/web-development" },
  { icon: "lnil-3d", name: "App Marketing", url: "/services/web-development" },
  { icon: "lnil-3d", name: "Search Engine Optimization", url: "/services/web-development" },
  { icon: "lnil-3d", name: "Patent Consulting", url: "/services/web-development" },
];

const ServiceListThree = () => {
  return (
    <>
      <ul>
        {serviceContent.map((val, i) => (
          <li key={i}>
            <a href={val.url}>
              <i className={"lnil " + val.icon}></i> {val.name}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ServiceListThree;
