import React from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Social from "../../social/Social";

const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation mobile-menu-full">
        <Sidebar>
          <Menu
            menuItemStyles={{

              button: {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                [`&.active`]: {
                  backgroundColor: 'white',
                  color: '#222',
                },

              },
            }}
          >
            <MenuItem component={<Link to="/" />}> Home</MenuItem>
            <MenuItem component={<Link to="/about-us" />}> About Us</MenuItem>
            <MenuItem component={<Link to="/services" />}> Services</MenuItem>
            <MenuItem component={<Link to="/case-studies" />}> Case Studies</MenuItem>
            <MenuItem component={<Link to="/contact-us" />}> Contact</MenuItem>
            <div className="ptf-offcanvas-menu__navigation ptf-offcanvas-menu__info">
              <h4 className="ptf-offcanvas-menu__info-title menu-header">Contact Info</h4>
              <a href="tel:5702300945" className="menu-links">(570) 230-0945</a>
              <a href="mailto:sales@covetech.net" className="menu-links">sales@covetech.net</a>
            </div>
          </Menu>
        </Sidebar>
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}

      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          @{new Date().getFullYear()} <span>CoveTech</span>. All Rights Reserved.{" "}
        </p>
        <Social />
      </div>
      {/* End .ptf-offcanvas-menu__footer */}
    </>
  );
};

export default MobileMenu;
